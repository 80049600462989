export const MainConfig = {
    AppDetails: {
        app_name: "Drop4U", 
        app_description: "Send.Receive.Relax",
        app_identifier: "com.drop4u.mob",
        ios_app_version: "2.6.0", 
        android_app_version: 8
    },

    FirebaseConfig: {
        apiKey: "AIzaSyCft8eG8J4sPP9hBCIAp4C5_Xfuanah39I",
        authDomain: "drop4u-23.firebaseapp.com",
        databaseURL: "https://drop4u-23-default-rtdb.firebaseio.com",
        projectId: "drop4u-23",
        storageBucket: "drop4u-23.appspot.com",
        messagingSenderId: "867714360464",
        appId: "1:867714360464:web:13a1391c43d5459341e415",
        measurementId: "G-40JD62QMS0"
    },

    Google_Map_API_Key: {
        ios: "AIzaSyDrLrBYTB_g3RlJdGhdkMwNtavJNTyhKAw",
        android: "AIzaSyByADzlYWWyOkDsA2D3pmckLVRkF6dx9hU",
        web: "AIzaSyCft8eG8J4sPP9hBCIAp4C5_Xfuanah39I"
    }   
}