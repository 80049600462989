import React from 'react';
import {
    Grid,
    //Typography
} from '@material-ui/core';
import DashboardCard from '../components/DashboardCard';
import { useSelector} from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { 
    //language
} from "config";

const ProfileWallet = () => {
    const usersdata = useSelector(state => state.usersdata);
    const bookinglistdata = useSelector(state => state.bookinglistdata);
   // const settingsdata = useSelector(state => state.settingsdata);
    const auth = useSelector(state => state.auth);




    return (
        bookinglistdata.loading || usersdata.loading ? <CircularLoading/> :
        <div>
            {/* <Typography style={{margin:"0px 0 5px 1px"}}>{"Wallet balance"}</Typography> */}
            <Grid 
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}>
                <Grid item>
                    <DashboardCard title={"Wallet balance"} image={require("../assets/img/money1.jpg").default}>{auth.info && auth.info.profile ? parseFloat(auth.info.profile.walletBalance).toFixed(2) : ''}</DashboardCard>
                </Grid>
                {/* <Grid item xs>
                    <DashboardCard title={language.this_month_text} image={require("../assets/img/money2.jpg").default}>{ settings.symbol +' ' +  monthlygross}</DashboardCard>
                </Grid>
                <Grid item xs>
                    <DashboardCard title={language.total} image={require("../assets/img/money3.jpg").default}>{ settings.symbol +' ' +  totalgross}</DashboardCard>
                </Grid> */}
            </Grid>

        </div>
        
    )
}
export default ProfileWallet;