import React,{ useState, useEffect } from 'react';
import {
    Grid,
    Paper,
    Typography
} from '@material-ui/core';
import Map from '../components/Map';
import { useSelector } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { 
    language
} from "config";
import Card from '@mui/material/Card';
import MaterialTable from 'material-table';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

const Dashboard = () => {
    const [mylocation, setMylocation] = useState(null);
    const [locations, setLocations] = useState([]);
    const usersdata = useSelector(state => state.usersdata);
    const bookinglistdata = useSelector(state => state.bookinglistdata);
    const auth = useSelector(state => state.auth);

    useEffect(()=>{
        if(mylocation == null){
            navigator.geolocation.getCurrentPosition(
                position => setMylocation({ 
                    lat: 9.075778952705326,
                    lng: 7.3999762458174105,
                }), 
                err => console.log(err)
            );
        }
    },[mylocation]);


    useEffect(()=>{
        if(usersdata.users){
            const drivers = usersdata.users.filter(user => user.usertype ==='driver' && ((user.fleetadmin === auth.info.uid && auth.info.profile.usertype === 'fleetadmin')|| auth.info.profile.usertype === 'admin'));  
            let locs = [];
            for(let i=0;i<drivers.length;i++){
                if(drivers[i].approved && drivers[i].driverActiveStatus && drivers[i].location){
                    locs.push({
                        id:i,
                        lat:drivers[i].location.lat,
                        lng:drivers[i].location.lng,
                        drivername:drivers[i].firstName + ' ' + drivers[i].lastName
                    });
                }
            }
            setLocations(locs);
        }
    },[usersdata.users,auth.info.profile,auth.info.uid]);

    const [data, setData] = useState([]);
    // const cartypes = useSelector(state => state.cartypes);
  
    useEffect(()=>{
      if(usersdata.users){
          setData(usersdata.users.filter(user => user.usertype ==='driver' && ((user.fleetadmin === auth.info.uid && auth.info.profile.usertype === 'fleetadmin')|| auth.info.profile.usertype === 'admin')));
      }else{
        setData([]);
      }
    },[usersdata.users,auth.info.profile.usertype,auth.info.uid]);
  
  
    const columns = [
        { title: language.first_name, field: 'firstName' },
        { title: language.last_name, field: 'lastName' },
        { title: language.queue,  field: 'queue'}
    ];

    return (
        bookinglistdata.loading || usersdata.loading ? <CircularLoading/> :
        <>
        <div>
            { mylocation?
            <Paper>
                {/* <Typography variant="h5" style={{margin:"5px 0 0 15px"}}>{language.real_time_driver_section_text}</Typography> */}
                <Map mapcenter={mylocation} locations={locations}
                    loadingElement={<div style={{ height: `480px` }} />}
                    containerElement={<div style={{ height: `480px` }} />}
                    mapElement={<div style={{ height: `480px` }} />}
                />
            </Paper>
            :
            <Typography variant="inherit" style={{margin:"20px 0 0 15px",color:'#00adef'}}>{language.allow_location}</Typography>
            }
        </div>
        <div>
            <Typography style={{margin:"20px 20px 10px 10px"}}>{"Deliveries Information"}</Typography>
        
    <Grid container spacing={2}>

        <Grid item xs={4}>
        <Card >
            <MaterialTable
                title={"ACTIVE Drivers & STATUS"}
                columns={columns}
                data={data}
                options={{
                    search: false,
                    headerStyle: {
                    backgroundColor: '#00adef',
                    color: '#fff',
                    fontWeight:"bold"
                    },
                }}
                />
        </Card>
    </Grid>
        <Grid item xs={8}>
            <Card >
            <CardMedia
          component="img"
          height="310"
          image={require("../assets/img/background-sample.png").default}
          alt="income"
        />
            <CardContent>
            <Typography gutterBottom variant="h5" component="div">
            Deliveries Graph last 30 days
            </Typography>
            <Typography variant="h6" color="text.secondary">{ 'Number of trips' }</Typography>
            </CardContent>
            </Card>
        
        </Grid>
    </Grid>
        </div>
        </>
        
    )
}

export default Dashboard;