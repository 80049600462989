import React,{ useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import { 
  language
} from 'config';

export default function InvoicesHistory() {
const settings = useSelector(state => state.settingsdata.settings);
  const date = new Date().toLocaleDateString();

  const columns = [
    {
        name: 'CompanyDetails',
         label: "Provider Details",
         options: {
          sort: false,
          filter: false,
          customBodyRender:() => {
            return settings.CompanyDetails
          }
         }
        },
    {
        name:  "customer_name",
         label: "Sender Details",
         field: "customer_name",
         options: {
          sort: false,
          filter: false,
         }
        },

    {
        name: "tripdate",
         label: "Delivery Time/date",
         field: "tripdate",
         options: {
          sort: true,
          filter: false,
         }
        },
        {
            name: "customer_paid",
             label: language.Customer_paid,
             field: 'customer_paid',
             options: {
              filter: false,
              sort: true,
             }
            },
            {
              name:  "date",
               label: "Date",
               //field: date,
               options: {
                sort: false,
                filter: false,
                customBodyRender:() => {
                  return date
                }
               }
              },
              {
                name: "id",
                label: "ID",
                options: {
                  filter: false,
                  sort: false,
                },
              },

   ];

  const [data, setData] = useState([]);
   const bookinglistdata = useSelector(state => state.bookinglistdata);
 
   useEffect(()=>{
         if(bookinglistdata.bookings){
             setData(bookinglistdata.bookings);
         }else{
           setData([]);
         }
   },[bookinglistdata.bookings]);

   
   const options = {
     filter:false,
     selectableRows: false,
     searchPlaceholder: "Search Invoices",
     rowsPerPage: 50,
    rowsPerPageOptions: [50, 100, 200],
   };

return (
<MUIDataTable
  title={"All Invoices"}
  data={data}
  columns={columns}
  options={options}
/>
);
}
