import React from 'react';
import './whatsapp.css';

export default function WhatsApp() {
  return (
              <a
              href="https://wa.me/2349051331094"
              class="whatsapp_float"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class=" fab fa-whatsapp whatsapp-icon "></i>
            </a>

  );
}