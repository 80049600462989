import React,{ useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import MUIDataTable from "mui-datatables";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Chip from "@mui/material/Chip";
import ConfirmationDialogRaw from "../components/ConfirmationDialogRaw";
import WrongLocationIcon from "@mui/icons-material/WrongLocation";
import { 
  // features, dateStyle,
  language } from "config";
import { FirebaseContext } from "common";
import AlertDialog from "../components/AlertDialog";

const BookingHistory = () => {
  const { api } = useContext(FirebaseContext);
  const {cancelBooking} = api;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [, setRole] = useState(null);
  const [data, setData] = useState([]);
  const bookinglistdata = useSelector((state) => state.bookinglistdata);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState("");
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });

  useEffect(() => {
    if (bookinglistdata.bookings) {
      setData(bookinglistdata.bookings);
    } else {
      setData([]);
    }
  }, [bookinglistdata.bookings]);

  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setRole(auth.info.profile.usertype);
    }
  }, [auth.info]);

  const onConfirmClose = (value) => {
    if (value) {
      dispatch(
        cancelBooking({
          reason: value,
          booking: selectedBooking,
        })
      );
    }
    setOpenConfirm(false);
  };

  //ACTIVATE THE CANCEL MODAL
  const handleDelete = () => {
    setOpenConfirm(true);
  };

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded",
        filter: false,
        sort: false,
      },
    },
    {
      name: "tripdate",
      label: language.booking_date,
      field: "tripdate",
      options: {
        disablePadding: true,
        sort: true,
        filter: false,
      },
    },
    {
      name: "status",
      label: language.booking_status,
      field: { render: (rowData) => <span>{language[rowData.status]}</span> },
      options: {
        customBodyRender: (value) => {
          if (value === "COMPLETE")
            return <Chip label="COMPLETED" color="success" size="small" />;
          else if (value === "CANCELLED")
            return <Chip label="CANCELLED" color="error" size="small" />;
          else if (value === "NEW")
            return (
              <Chip
                label="NEW"
                variant="outlined"
                color="warning"
                size="small"
              />
            );
          else if (value === "STARTED")
            return <Chip label="STARTED" color="warning" size="small" />;
            else if (value === "PAYMENT_PENDING")
            return (
              <Chip
                label="PAYMENT PENDING"
                variant="outlined"
                color="primary"
                size="small"
              />
            );

          else if (value === "PENDING")
            return (
              <Chip
                label="PENDING"
                variant="outlined"
                color="warning"
                size="small"
              />
            );
          else if (value === "PAID")
            return <Chip label="PAID" color="success" size="small" />;
          else if (value === "REACHED")
            return <Chip label="REACHED" color="secondary" size="small" />;
          else if (value === "ARRIVED")
            return <Chip label="ARRIVED" color="secondary" size="small" />;
          else
            return (
              <Chip
                label="ACCEPTED"
                variant="outlined"
                color="secondary"
                size="small"
              />
            );
        },
        hint: "delivery status",
        filter: true,
        sort: false,
      },
    },
    {
      name: "driver_name",
      label: language.assign_driver,
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "customer_name",
      label: language.customer_name,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "deliveryPerson",
      label: language.deliveryPerson,
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "deliveryPersonPhone",
      label: language.deliveryPersonPhone,
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "dropAddress",
      label: language.drop_address,
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "otp",
      label: "Proof PIN",
      field: "customer_paid",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "pickup_image",
      label: language.take_pickup_image,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) =>
          !!value ? (
            <img alt="Pick Up" src={value} style={{ width: 150 }} />
          ) : null,
      },
    },
    {
      name: "deliver_image",
      label: language.take_deliver_image,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) =>
          !!value ? (
            <img alt="Deliver" src={value} style={{ width: 150 }} />
          ) : null,
      },
    },
    {
      name: "status",
      label: language.cancel_booking,
      options: {
        hint: "You can Cancel Delivery only while new or pending",
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          if (value === "NEW") {
            const handleClick = () => {
              const bookingId = tableMeta.rowData?.[0];
              const booking = data.find((b) => b.id === bookingId);
              if (!booking) {
                setCommonAlert({
                  open: false,
                  msg: "Something went wrong, please retry",
                });
              } else {
                setSelectedBooking(booking);
                handleDelete();
              }
            };
            return (
              <Chip
                size="small"
                color="error"
                label="Cancel"
                onClick={handleClick}
                onDelete={handleClick}
                deleteIcon={<WrongLocationIcon />}
              />
            );
          } else {
            return <Chip label="ONGOING" size="small" />;
          }
        },
      },
    },
  ];

  const options = {
    selectableRows: false,
    filter: true,
    filterType: "dropdown",
    rowsPerPage: 50,
    rowsPerPageOptions: [50, 100, 200],
    expandableRows: true,
    expandableRowsOnClick: true,
    searchPlaceholder: "Search all deliveries",
    renderExpandableRow: (rowData) => {
      return (
        <>
          <tr>
            <td colSpan={12}>
              <TableContainer component={Paper}>
                <Table style={{ minWidth: "300" }} aria-label="simple table">
                  <TableHead></TableHead>
                  <TableBody>
                    {columns.map((col, index) => {
                      return (
                        <TableRow>
                          <TableCell>
                            {col.label}: {rowData[index]}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </td>
          </tr>
        </>
      );
    },
  };

  return (
    <>
      <MUIDataTable
        title={"Current Deliveries"}
        data={data}
        columns={columns}
        options={options}
      />
      {/* <SelectDriverModal
        visible={driverForBooking.modal}
        bookings={driverForBooking.bookings}
        onClose={() => {
          setDriverForBooking({
            booking: [],
            modal: false,
          });
        }}
      /> */}
      {/* <ParcelsModal
        visible={parcelsModal.visible}
        booking={parcelsModal.booking}
        onClose={() =>
          setParcelsModal({
            visible: false,
            booking: null,
          })
        }
      /> */}
      <ConfirmationDialogRaw
        open={openConfirm}
        onClose={onConfirmClose}
        value={""}
      />
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    </>
  );

};

export default BookingHistory;



// import React,{ useState, useEffect, useContext } from 'react';
// import MaterialTable from 'material-table';
// import CircularLoading from "../components/CircularLoading";
// import { useSelector, useDispatch } from "react-redux";
// import ConfirmationDialogRaw from '../components/ConfirmationDialogRaw';
// import { 
//   features,
//   dateStyle,
//   language
// } from 'config';
// import { FirebaseContext } from 'common';

// const BookingHistory = () => {
//   const { api } = useContext(FirebaseContext);
//   const {
//     cancelBooking
//   } = api;
//   const dispatch = useDispatch();
//   const auth = useSelector(state => state.auth);
//   const [role, setRole] = useState(null);

//   const columns =  [
      
      
//       {title: language.booking_date, field: 'tripdate', render: rowData => rowData.tripdate?new Date(rowData.tripdate).toLocaleString(dateStyle):null},
//       { title: language.assign_driver, field: 'driver_name' },
//       { title: language.booking_status, field: 'status', render: rowData => <span>{language[rowData.status]}</span> },
//       {title: language.delivery_type, field: 'carType' },
//       { title: language.trip_cost, field: 'trip_cost' },  
//       {title: language.customer_name,field: 'customer_name'},
//       { title: language.pickup_address, field: 'pickupAddress'},
//       { title: language.deliveryPerson, field: 'deliveryPerson' },
//       { title: language.drop_address, field: 'dropAddress' },
//       { title: language.deliveryPersonPhone, field: 'deliveryPersonPhone' },
//       { title: language.pickUpInstructions, field: 'pickUpInstructions' },
//       { title: language.deliveryInstructions, field: 'deliveryInstructions' },
//       { title: language.parcel_type, render: rowData => <span>{rowData.parcelTypeSelected?rowData.parcelTypeSelected.description + " (" + rowData.parcelTypeSelected.amount + ")":""}</span> },
//       { title: language.parcel_option, render: rowData => <span>{rowData.optionSelected?rowData.optionSelected.description + " (" + rowData.optionSelected.amount + ")":""}</span> },
//       { title: language.take_pickup_image,  field: 'pickup_image',render: rowData => rowData.pickup_image?<img alt='Pick Up' src={rowData.pickup_image} style={{width: 150}}/>:null, editable:'never'},
//       { title: language.take_deliver_image,  field: 'deliver_image',render: rowData => rowData.deliver_image?<img alt='Deliver' src={rowData.deliver_image} style={{width: 150}}/>:null, editable:'never'},
//       { title: language.vehicle_reg_no, field: 'vehicleNumber' },
//       //{ title: language.cancellation_reason, field: 'reason'},
//       { title: language.trip_start_time, field: 'trip_start_time' },
//       { title: language.trip_end_time, field: 'trip_end_time' },
//       { title: language.total_time, field: 'total_trip_time' },
//       { title: language.distance, field: 'estimateDistance' },
//       //{ title: language.trip_cost_driver_share, field: 'driver_share'},
//       //{ title: language.convenience_fee, field: 'convenience_fees'},
//       { title: language.discount_ammount, field: 'discount'},      
//       { title: language.Customer_paid, field: 'customer_paid'},
//       //{ title: language.payment_mode, field: 'payment_mode'},
//       //{ title: language.payment_gateway, field: 'gateway'},
//       { title: language.cash_payment_amount, field: 'cashPaymentAmount'},
//       { title: language.card_payment_amount, field: 'cardPaymentAmount'},
//       { title: language.wallet_payment_amount, field: 'usedWalletMoney'},
//       { title: language.settings_proof_pin_dash, field: 'otp'},
//       { title: language.booking_id, field: 'id' }
//   ];
//   const [data, setData] = useState([]);
//   const [openConfirm, setOpenConfirm] = useState(false);
//   const [selectedBooking, setSelectedBooking] = useState('');
//   const bookinglistdata = useSelector(state => state.bookinglistdata);

//   useEffect(()=>{
//         if(bookinglistdata.bookings){
//             setData(bookinglistdata.bookings);
//         }else{
//           setData([]);
//         }
//   },[bookinglistdata.bookings]);

//   useEffect(() => {
//     if(auth.info && auth.info.profile){
//       setRole(auth.info.profile.usertype);
//     }
//   }, [auth.info]);

//   const onConfirmClose=(value)=>{
//     if(value){
//       dispatch(cancelBooking({
//         reason:value,
//         booking:selectedBooking
//       }));
//     }
//     setOpenConfirm(false);
//   }
  
//   return (
//     bookinglistdata.loading? <CircularLoading/>:
//     <div>
//     <MaterialTable
//       title={language.booking_title}
//       columns={columns}
//       data={data}
//       options={{
//         actionsColumnIndex: 1,
//         exportButton: features.AdminEdits,
//         sorting: true,
//         searchFieldAlignment:'left',
//         headerStyle: {
//           backgroundColor: '#494A6B',
//           color: '#fff',
//           fontWeight:"bold"
//         },
//       }}
//       actions={[
//         rowData => ({
//           icon: 'cancel',
//           tooltip: language.cancel_booking,
//           disabled: rowData.status==='NEW' || rowData.status==='ACCEPTED' || rowData.status==='PAYMENT_PENDING'? false:true,
//           onClick: (event, rowData) => {
//             if(features.AdminEdits && (role==='dispatcher' || role ==='admin')){
//               if(rowData.status==='NEW' || rowData.status==='ACCEPTED'){
//                 setSelectedBooking(rowData);
//                 setOpenConfirm(true);
//               }else{
//                 setTimeout(()=>{
//                   dispatch(cancelBooking({
//                     reason: language.cancelled_incomplete_booking,
//                     booking:rowData
//                   }));
//                 },1500);
//               }
//             }else{
//               alert(language.demo_mode);
//             }
//           }         
//         }),
//       ]}
//     />
//     <ConfirmationDialogRaw
//       open={openConfirm}
//       onClose={onConfirmClose}
//       value={''}
//     />
//     </div>

//   );
// }

// export default BookingHistory;
