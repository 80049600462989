import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Select,
  MenuItem,
  Grid,
  Button,
  Typography,
  TextField,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Modal,
} from "@material-ui/core";
import GoogleMapsAutoComplete from "../components/GoogleMapsAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import AlertDialog from "../components/AlertDialog";
import { language } from "config";
import { makeStyles } from "@material-ui/core/styles";
import UsersCombo from "../components/UsersCombo";
import { FirebaseContext } from "common";
import { features } from "config";
import GridItem from "components/Grid/GridItem.js";
import RegularButton from "components/CustomButtons/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: 480,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #494A6B",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  container: {
    zIndex: "12",
    color: "#F6F6FD",
    alignContent: "center",
  },
  title: {
    color: "#000000",
    fontFamily: "AdihausDIN-Bold",
    fontSize: "22",
  },
  gridcontainer: {
    alignContent: "center",
  },
  items: {
    margin: 0,
    width: "100%",
  },
  input: {
    fontSize: 18,
    color: "#000000",
  },
  inputdimmed: {
    fontSize: 16,
    color: "#000000",
  },
  carphoto: {
    height: "18px",
    marginRight: "10px",
  },
  buttonStyle: {
    margin: 10,
    width: "100%",
    height: "100%",
    backgroundColor: "#00adef",
    color: "white",
    fontSize: 18,
  },
}));

export default function PhoneDelivery(props) {
  const { api } = useContext(FirebaseContext);
  const {
    getEstimate,
    clearEstimate,
    addBooking,
    clearBooking,
    MinutesPassed,
    GetDateString,
  } = api;
  const dispatch = useDispatch();
  const classes = useStyles();
  const cartypes = useSelector((state) => state.cartypes.cars);
  const estimatedata = useSelector((state) => state.estimatedata);
  const bookingdata = useSelector((state) => state.bookingdata);
  const userdata = useSelector((state) => state.usersdata);
  const settings = useSelector((state) => state.settingsdata.settings);
  const [carType, setCarType] = useState(language.select_delivery);
  const [pickupAddress, setPickupAddress] = useState(null);
  const [dropAddress, setDropAddress] = useState(null);
  const [optionModalStatus, setOptionModalStatus] = useState(false);
  const [estimateModalStatus, setEstimateModalStatus] = useState(false);
  const [selectedCarDetails, setSelectedCarDetails] = useState(null);
  const [users, setUsers] = useState(null);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [userCombo, setUserCombo] = useState(null);
  const [driverCombo, setDriverCombo] = useState(null);
  const [autoDispatch, setAutoDispatch] = useState(false);
  const [estimateRequested, setEstimateRequested] = useState(false);
  const [bookingType, setBookingType] = useState("Book Now");
  const rootRef = useRef(null);
  const [tempRoute, setTempRoute] = useState();
  const pickupPlaceInputRef = useRef();

  useEffect(() => {
    if (userCombo?.startAddress) {
      pickupPlaceInputRef.current?.searchForFirstOption?.(
        userCombo.startAddress
      );
    }
  }, [userCombo]);

  useEffect(() => {
    setAutoDispatch(!driverCombo);
  }, [driverCombo]);

  const [instructionData, setInstructionData] = useState({
    deliveryPerson: "",
    deliveryPersonPhone: "",
    pickUpInstructions: "",
    deliveryInstructions: "",
    optionIndex: 0,
    optionSelected: null,
  });

  const handleChange = (e) => {
if(e.target.name === 'optionIndex'){
          setInstructionData({ 
            ...instructionData,
            optionIndex: parseInt(e.target.value),
            optionSelected: selectedCarDetails.options[e.target.value]
          });
        }else{
          setInstructionData({ ...instructionData, [e.target.name]: e.target.value });
        }
      };

  const [selectedDate, setSelectedDate] = useState(GetDateString());

  const handleCarSelect = (event) => {
    setCarType(event.target.value);
    let carDetails = null;
    for (let i = 0; i < cartypes.length; i++) {
      if (cartypes[i].name === event.target.value) {
        carDetails = cartypes[i];
        let instObj = { ...instructionData };
        if (Array.isArray(cartypes[i].options)) {
          instObj.optionSelected = cartypes[i].options[0];
          instObj.optionIndex = 0;
        }
        setInstructionData(instObj);
      }
    }
    setSelectedCarDetails(carDetails);
  };

  const handleBookTypeSelect = (event) => {
    setBookingType(event.target.value);
    if (bookingType === "Book Later") {
      setSelectedDate(GetDateString());
    }
  };

  const onDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  useEffect(() => {
    if (estimatedata.estimate && estimateRequested) {
      setEstimateRequested(false);
      setEstimateModalStatus(true);
    }
    if (userdata.users) {
      let arr = [];
      for (let i = 0; i < userdata.users.length; i++) {
        let user = userdata.users[i];
        if (user.usertype === "dispatcher") {
          arr.push({
            firstName: user.firstName,
            lastName: user.lastName,
            mobile: user.mobile,
            email: user.email,
            uid: user.id,
            desc:
              user.firstName +
              " " +
              user.lastName +
              " (" +
              (features.AdminEdits ? user.mobile : "Hidden") +
              ") " +
              (features.AdminEdits ? user.email : "Hidden"),
            pushToken: user.pushToken,
            startAddress: user?.startAddress || "",
          });
        }
      }
      setUsers(arr);
    }
  }, [estimatedata.estimate, userdata.users, estimateRequested]);

  const handleGetOptions = (e) => {
    e.preventDefault();
    setEstimateRequested(true);
    if (userCombo && pickupAddress && dropAddress && selectedCarDetails) {
      const directionService = new window.google.maps.DirectionsService();
      directionService.route(
        {
          origin: new window.google.maps.LatLng(
            pickupAddress.coords.lat,
            pickupAddress.coords.lng
          ),
          destination: new window.google.maps.LatLng(
            dropAddress.coords.lat,
            dropAddress.coords.lng
          ),
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            const route = {
              distance: result.routes[0].legs[0].distance.value / 1000,
              duration: result.routes[0].legs[0].duration.value,
              polylinePoints: result.routes[0].overview_polyline,
            };
            setTempRoute(route);
            if (bookingType === "Book Now") {
              if (
                Array.isArray(selectedCarDetails.options)
                
              ) {
                setOptionModalStatus(true);
              } else {
                let estimateRequest = {
                  pickup: pickupAddress,
                  drop: dropAddress,
                  carDetails: selectedCarDetails,
                  instructionData: instructionData,
                  routeDetails: route,
                };
                dispatch(getEstimate(estimateRequest));
              }
            } else {
              if (bookingType === "Book Later" && selectedDate) {
                if (MinutesPassed(selectedDate) >= 15) {
                  if (
                    Array.isArray(selectedCarDetails.options)
                  ) {
                    setOptionModalStatus(true);
                  } else {
                    let estimateRequest = {
                      pickup: pickupAddress,
                      drop: dropAddress,
                      carDetails: selectedCarDetails,
                      instructionData: instructionData,
                      routeDetails: route,
                    };
                    dispatch(getEstimate(estimateRequest));
                  }
                } else {
                  setCommonAlert({
                    open: true,
                    msg: language.past_booking_error,
                  });
                }
              } else {
                setCommonAlert({ open: true, msg: language.select_proper });
              }
            }
          } else {
            setCommonAlert({ open: true, msg: language.place_to_coords_error });
          }
        }
      );
    } else {
      setCommonAlert({ open: true, msg: language.select_proper });
    }
  };

  const handleGetEstimate = (e) => {
    e.preventDefault();
    setOptionModalStatus(false);
    let estimateRequest = {
      pickup: pickupAddress,
      drop: dropAddress,
      carDetails: selectedCarDetails,
      instructionData: instructionData,
      routeDetails: tempRoute,
    };
    dispatch(getEstimate(estimateRequest));
  };

  const confirmBooking = (e) => {
    e.preventDefault();
    if (instructionData.deliveryPerson && instructionData.deliveryPersonPhone) {
      setEstimateModalStatus(false);
      let bookingObject = {
        pickup: pickupAddress,
        drop: dropAddress,
        carDetails: selectedCarDetails,
        userDetails: {
          uid: userCombo.uid,
          profile: {
            firstName: userCombo.firstName,
            lastName: userCombo.lastName,
            mobile: userCombo.mobile,
            pushToken: userCombo.pushToken,
          },
        },
        estimate: estimatedata.estimate,
        instructionData: instructionData,
        tripdate: new Date(selectedDate).toString(),
        bookLater: bookingType === "Book Later" ? true : false,
        settings: settings,
        booking_type_web: true,
        manualDispatch: !autoDispatch,
      };

      if (driverCombo && driverCombo?.id) {
        bookingObject.requestedDrivers = {
          [driverCombo.id]: true,
        };
      }
      dispatch(addBooking(bookingObject));
    } else {
      alert(language.deliveryDetailMissing);
    }
  };
  const handleOptionModalClose = (e) => {
    e.preventDefault();
    setOptionModalStatus(false);
  };

  const handleEstimateModalClose = (e) => {
    e.preventDefault();
    setEstimateModalStatus(false);
    dispatch(clearEstimate());
    setEstimateRequested(false);
  };

  const handleEstimateErrorClose = (e) => {
    e.preventDefault();
    dispatch(clearEstimate());
    setEstimateRequested(false);
  };

  const handleBookingAlertClose = (e) => {
    e.preventDefault();
    dispatch(clearBooking());
    dispatch(clearEstimate());
    clearForm();
  };

  const clearForm = () => {
    setUserCombo(null);
    setPickupAddress(null);
    setDropAddress(null);
    setSelectedCarDetails(null);
    setDriverCombo(null)
    setCarType(language.select_delivery);
    setBookingType(language.book_now);
    setEstimateRequested(false);
  };

  const handleBookingErrorClose = (e) => {
    e.preventDefault();
    dispatch(clearBooking());
    setEstimateRequested(false);
  };

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  return (
    <div className={classes.container} ref={rootRef}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="h5" variant="h5" className={classes.title}>
              {language.newdelivery}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {users ? (
              <UsersCombo
                className={classes.items}
                placeholder={language.select_user}
                users={users}
                value={userCombo}
                onChange={(event, newValue) => {
                  setUserCombo(newValue);
                }}
              />
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <GoogleMapsAutoComplete
              ref={pickupPlaceInputRef}
              variant={"standard"}
              placeholder={language.pickup_location}
              value={pickupAddress}
              className={classes.items}
              onChange={(value) => {
                setPickupAddress(value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <GoogleMapsAutoComplete
              placeholder={language.drop_location}
              variant={"standard"}
              value={dropAddress}
              className={classes.items}
              onChange={(value) => {
                setDropAddress(value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {cartypes ? (
              <Select
                id="car-type-native"
                value={carType}
                onChange={handleCarSelect}
                variant="outlined"
                fullWidth
                className={
                  carType === language.select_delivery
                    ? classes.inputdimmed
                    : classes.input
                }
              >
                <MenuItem
                  value={language.select_delivery}
                  key={language.select_delivery}
                >
                  {language.select_delivery}
                </MenuItem>
                {cartypes.map((car) => (
                  <MenuItem key={car.name} value={car.name}>
                    <img
                      src={car.image}
                      className={classes.carphoto}
                      alt="car types"
                    />
                    {car.name}
                  </MenuItem>
                ))}
              </Select>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
            // disabled={!!driverCombo || !autoDispatch}
              id="booking-type-native"
              value={bookingType}
              onChange={handleBookTypeSelect}
              className={classes.input}
              variant="outlined"
              fullWidth
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem key={"Book Now"} value={"Book Now"}>
                {language.book_now}
              </MenuItem>
              <MenuItem key={"Book Later"} value={"Book Later"}>
                {language.book_later}
              </MenuItem>
            </Select>
          </Grid>
          {bookingType === "Book Later" ? (
            <Grid item xs={12} sm={6}>
              <TextField
                id="datetime-local"
                label={language.booking_date_time}
                type="datetime-local"
                variant="standard"
                fullWidth
                className={classes.commonInputStyle}
                InputProps={{
                  className: classes.input,
                }}
                value={selectedDate}
                onChange={onDateChange}
              />
            </Grid>
          ) : null}
          {/* <GridItem
            xs={12}
            style={{ display: "flex", flexDirection: "column", paddingTop: 30 }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={autoDispatch}
                  onChange={(e) => {
                    setAutoDispatch(e.target.checked);
                  }}
                  color="warning"
                />
              }
              label="Auto dispatch to drivers?"
            />
          </GridItem> */}
          <GridItem
            xs={12}
            style={{ display: "flex", flexDirection: "column", paddingTop: 30 }}
          >
          </GridItem>
          {/* <GridItem xs={12} sm={12} md={6} lg={6} style={{display: "flex", flexDirection: "column", paddingTop: 30}}>
            <Typography style={{flex: 1, color: "black"}}  variant="h6">Assign Driver</Typography>
            <IconButton aria-label="Assign Driver">
                    <DriveEtaIcon color="success" /> -Assign Driver-
                  </IconButton>
            </GridItem> */}
          <Grid item xs={12} sm={12}>
            <RegularButton
              size="large"
              onClick={handleGetOptions}
              variant="contained"
              className={classes.buttonStyle}
            >
              {language.phone_booking_btn}
            </RegularButton>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={optionModalStatus}
        onClose={handleOptionModalClose}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid container spacing={2} className={classes.paper}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {selectedCarDetails ? (
              <FormControl component="fieldset">
              </FormControl>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {selectedCarDetails && selectedCarDetails.options ? (
              <FormControl component="fieldset">
                <FormLabel component="legend">{language.options}</FormLabel>
                <RadioGroup
                  name="optionIndex"
                  value={instructionData.optionIndex}
                  onChange={handleChange}
                >
                  {selectedCarDetails.options.map((element, index) => (
                    <FormControlLabel
                      key={element.description}
                      value={index}
                      control={<Radio />}
                      label={
                        settings.symbol +
                        " " +
                        element.amount +
                        " - " +
                        element.description
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
          <RegularButton
              onClick={handleGetEstimate}
              variant="contained"
              color="error"
              style={{ marginLeft: 10 }}
            >
              {language.get_estimate}
            </RegularButton>
            <Button
            simple
              onClick={handleOptionModalClose}
              variant="text"
            >
              {language.cancel}
            </Button>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={estimateModalStatus}
        onClose={handleEstimateModalClose}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid container spacing={1} className={classes.paper}>
          <Typography
           component="h1"
           variant="h5"
           className={classes.title}
            style={{ marginTop: 15 }}
          >
            {language.delivery_information}
          </Typography>
          <Grid item xs={12}>
            <TextField
              color="warning"
              variant="standard"
              margin="normal"
              fullWidth
              id="deliveryPerson"
              label={language.deliveryPerson}
              name="deliveryPerson"
              autoComplete="deliveryPerson"
              onChange={handleChange}
              value={instructionData.deliveryPerson}
              autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              color="success"
              variant="standard"
              margin="normal"
              fullWidth
              id="deliveryPersonPhone"
              label={language.deliveryPersonPhone}
              name="deliveryPersonPhone"
              autoComplete="deliveryPersonPhone"
              onChange={handleChange}
              value={instructionData.deliveryPersonPhone}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              color="success"
              variant="outlined"
              multiline
              rows={3}
              margin="normal"
              fullWidth
              id="pickUpInstructions"
              label={language.pickUpInstructions}
              name="pickUpInstructions"
              autoComplete="pickUpInstructions"
              onChange={handleChange}
              value={instructionData.pickUpInstructions}
            />
          </Grid>

          {/* //To add if its type of contract to show, otherwise NOT */}

          <Grid item xs={12}>
            <TextField
              color="success"
              variant="outlined"
              margin="normal"
              fullWidth
              id="deliveryInstructions"
              label={language.deliveryInstructions}
              name="deliveryInstructions"
              autoComplete="deliveryInstructions"
              onChange={handleChange}
              value={instructionData.deliveryInstructions}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography color={"primary"} style={{ fontSize: 20 }}>
              {language.total} - {settings ? settings.symbol : null}{" "}
              {estimatedata.estimate
                ? estimatedata.estimate.estimateFare
                : null}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
          <RegularButton
              onClick={confirmBooking}
              variant="contained"
              color="warning"
              style={{ marginLeft: 10 }}
            >
              {language.book_now}
            </RegularButton>
          <Button
          simple
              onClick={handleEstimateModalClose}
              variant="text"
              color="warning"
            >
              {language.cancel}
            </Button>
            
          </Grid>
        </Grid>
      </Modal>
      <AlertDialog
        open={bookingdata.booking ? true : false}
        onClose={handleBookingAlertClose}
      >
        {bookingdata.booking
          ? language.booking_success + bookingdata.booking.booking_id
          : null}
      </AlertDialog>
      <AlertDialog
        open={bookingdata.error.flag}
        onClose={handleBookingErrorClose}
      >
        {bookingdata.error.msg}
      </AlertDialog>
      <AlertDialog
        open={estimatedata.error.flag}
        onClose={handleEstimateErrorClose}
      >
        {estimatedata.error.msg}
      </AlertDialog>
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    </div>
  );
}


// import React, { useState, useEffect, useContext, useRef } from 'react';
// import {
//   Select,
//   MenuItem,
//   Grid,
//   Button,
//   Typography,
//   TextField,
//   FormControlLabel,
//   FormControl,
//   FormLabel,
//   Radio,
//   RadioGroup,
//   Modal
// } from '@material-ui/core';
// import GoogleMapsAutoComplete from '../components/GoogleMapsAutoComplete';
// import { useSelector, useDispatch } from "react-redux";
// import AlertDialog from '../components/AlertDialog';
// import { language } from 'config';
// import { makeStyles } from '@material-ui/core/styles';
// import UsersCombo from '../components/UsersCombo';
// import { FirebaseContext } from 'common';
// import { features } from 'config';

// const useStyles = makeStyles(theme => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(1),
//     },
//   },
//   '@global': {
//     body: {
//       backgroundColor: theme.palette.common.white,
//     },
//   },
//   modal: {
//     display: 'flex',
//     padding: theme.spacing(1),
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   paper: {
//     width:480,
//     backgroundColor: theme.palette.background.paper,
//     border: '2px solid #494A6B',
//     boxShadow: theme.shadows[5],
//     padding: theme.spacing(2, 4, 3),
//   },
//   container: {
//     zIndex: "12",
//     color: "#F6F6FD",
//     alignContent: 'center'
//   },
//   title: {
//     color: "#494A6B",
//     fontFamily: 'AdihausDIN-Bold',
//     fontSize:'22',
//   },
//   gridcontainer: {
//     alignContent: 'center'
//   },
//   items: {
//     margin: 0,
//     width: '100%'
//   },
//   input: {
//     fontSize: 18,
//     color: "#494A6B"
//   },
//   inputdimmed: {
//     fontSize: 16,
//     color: "#494A6B"
//   },
//   carphoto: {
//     height: '18px',
//     marginRight: '10px'
//   },
//   buttonStyle: {
//     margin: 10,
//     width: '100%',
//     height: '100%',
//     backgroundColor:"#494A6B",
//     color:"white",
//     fontSize: 18
//   }
// }));

// export default function PhoneDelivery(props) {
//   const { api } = useContext(FirebaseContext);
//   const {
//     getEstimate,
//     clearEstimate,
//     addBooking,
//     clearBooking,
//     MinutesPassed,
//     GetDateString
//   } = api;
//   const dispatch = useDispatch();
//   const classes = useStyles();
//   const cartypes = useSelector(state => state.cartypes.cars);
//   const estimatedata = useSelector(state => state.estimatedata);
//   const bookingdata = useSelector(state => state.bookingdata);
//   const userdata = useSelector(state => state.usersdata);
//   const settings = useSelector(state => state.settingsdata.settings);
//   const [carType, setCarType] = useState(language.select_delivery);
//   const [pickupAddress, setPickupAddress] = useState(null);
//   const [dropAddress, setDropAddress] = useState(null);
//   const [optionModalStatus, setOptionModalStatus] = useState(false);
//   const [estimateModalStatus, setEstimateModalStatus] = useState(false);
//   const [selectedCarDetails, setSelectedCarDetails] = useState(null);
//   const [users, setUsers] = useState(null);
//   const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });
//   const [userCombo, setUserCombo] = useState(null);
//   const [estimateRequested, setEstimateRequested] = useState(false);
//   const [bookingType, setBookingType] = useState('Book Now');
//   const rootRef = useRef(null);
//   const [tempRoute, setTempRoute] = useState();
//   const pickupPlaceInputRef = useRef();

//   useEffect(() => {
//     if (userCombo?.startAddress) {
//       pickupPlaceInputRef.current?.searchForFirstOption?.(
//         userCombo.startAddress
//       );
//     }
//   }, [userCombo]);

//   const [instructionData,setInstructionData] = useState({
//     deliveryPerson : "",
//     deliveryPersonPhone: "",
//     pickUpInstructions: "",
//     deliveryInstructions: "",
//     parcelTypeIndex: 0,
//     optionIndex: 0,
//     parcelTypeSelected: null,
//     optionSelected: null
//   });

//   const handleChange = (e) => {
//     if(e.target.name === 'parcelTypeIndex'){
//       setInstructionData({ 
//         ...instructionData,
//         parcelTypeIndex: parseInt(e.target.value),
//         parcelTypeSelected: selectedCarDetails.parcelTypes[e.target.value]
//       });
//     }else if(e.target.name === 'optionIndex'){
//       setInstructionData({ 
//         ...instructionData,
//         optionIndex: parseInt(e.target.value),
//         optionSelected: selectedCarDetails.options[e.target.value]
//       });
//     }else{
//       setInstructionData({ ...instructionData, [e.target.name]: e.target.value });
//     }
//   };

//   const [selectedDate, setSelectedDate] = useState(GetDateString());

//   const handleCarSelect = (event) => {
//     setCarType(event.target.value);
//     let carDetails = null;
//     for (let i = 0; i < cartypes.length; i++) {
//       if (cartypes[i].name === event.target.value) {
//         carDetails = cartypes[i];
//         let instObj = {...instructionData};
//         if(Array.isArray(cartypes[i].parcelTypes)){
//           instObj.parcelTypeSelected = cartypes[i].parcelTypes[0];
//           instObj.parcelTypeIndex = 0;
//         }
//         if(Array.isArray(cartypes[i].options)){
//           instObj.optionSelected = cartypes[i].options[0];
//           instObj.optionIndex = 0;
//         }
//         setInstructionData(instObj);
//       }
//     }
//     setSelectedCarDetails(carDetails);
//   };

//   const handleBookTypeSelect = (event) => {
//     setBookingType(event.target.value);
//     if (bookingType === 'Book Later') {
//       setSelectedDate(GetDateString());
//     }
//   };

//   const onDateChange = (event) => {
//     setSelectedDate(event.target.value);
//   };
//   useEffect(() => {
//     if (estimatedata.estimate && estimateRequested) {
//       setEstimateRequested(false);
//       setEstimateModalStatus(true);
//     }
//     if (userdata.users) {
//       let arr = [];
//       for (let i = 0; i < userdata.users.length; i++) {
//         let user = userdata.users[i];
//         if (user.usertype === "dispatcher") {
//           arr.push({
//             firstName: user.firstName,
//             lastName: user.lastName,
//             mobile: user.mobile,
//             email: user.email,
//             uid: user.id,
//             desc:
//               user.firstName +
//               " " +
//               user.lastName +
//               " (" +
//               (features.AdminEdits ? user.mobile : "Hidden") +
//               ") " +
//               (features.AdminEdits ? user.email : "Hidden"),
//             pushToken: user.pushToken,
//             startAddress: user?.startAddress || "",
//           });
//         }
//       }
//       setUsers(arr);
//     }
//   }, [estimatedata.estimate, userdata.users, estimateRequested]);
//   // useEffect(() => {
//   //   if (estimatedata.estimate && estimateRequested) {
//   //     setEstimateRequested(false);
//   //     setEstimateModalStatus(true);
//   //   }
//   //   if (userdata.users) {
//   //     let arr = [];
//   //     for (let i = 0; i < userdata.users.length; i++) {
//   //       let user = userdata.users[i];
//   //       if (user.usertype === 'dispatcher') {
//   //         arr.push({
//   //           'firstName': user.firstName,
//   //           'lastName': user.lastName,
//   //           'mobile': user.mobile,
//   //           'email': user.email,
//   //           'uid': user.id,
//   //           'desc': user.firstName + ' ' + user.lastName + ' (' + (features.AdminEdits? user.mobile : "Hidden") + ') ' + (features.AdminEdits? user.email : "Hidden"),
//   //           'pushToken': user.pushToken
//   //         });
//   //       }
//   //     }
//   //     setUsers(arr);
//   //   }
//   // }, [estimatedata.estimate, userdata.users, estimateRequested]);

//   const handleGetOptions = (e) => {
//     e.preventDefault();
//     setEstimateRequested(true);
//     if (userCombo && pickupAddress && dropAddress && selectedCarDetails) {
//       const directionService = new window.google.maps.DirectionsService();
//       directionService.route(
//         {
//           origin: new window.google.maps.LatLng(pickupAddress.coords.lat, pickupAddress.coords.lng),
//           destination: new window.google.maps.LatLng(dropAddress.coords.lat, dropAddress.coords.lng),
//           travelMode: window.google.maps.TravelMode.DRIVING
//         },
//         (result, status) => {
//           if (status === window.google.maps.DirectionsStatus.OK) {
//             const route = {
//               distance:(result.routes[0].legs[0].distance.value / 1000),
//               duration:result.routes[0].legs[0].duration.value,
//               polylinePoints:result.routes[0].overview_polyline
//             };
//             setTempRoute(route);
//             if (bookingType === 'Book Now') {
//               if(Array.isArray(selectedCarDetails.options) || Array.isArray(selectedCarDetails.parcelTypes)){
//                 setOptionModalStatus(true);
//               }else{
//                 let estimateRequest = {
//                   pickup: pickupAddress,
//                   drop: dropAddress,
//                   carDetails: selectedCarDetails,
//                   instructionData: instructionData,
//                   routeDetails: route
//                 };
//                 dispatch(getEstimate(estimateRequest));
//               }
//             } else {
//               if (bookingType === 'Book Later' && selectedDate) {
//                 if (MinutesPassed(selectedDate) >= 15)  {
//                   if(Array.isArray(selectedCarDetails.options) || Array.isArray(selectedCarDetails.parcelTypes)){
//                     setOptionModalStatus(true);
//                   }else{
//                     let estimateRequest = {
//                       pickup: pickupAddress,
//                       drop: dropAddress,
//                       carDetails: selectedCarDetails,
//                       instructionData: instructionData,
//                       routeDetails: route
//                     };
//                     dispatch(getEstimate(estimateRequest));
//                   }
//                 } else {
//                   setCommonAlert({ open: true, msg: language.past_booking_error });
//                 }
//               } else {
//                 setCommonAlert({ open: true, msg: language.select_proper });
//               }
//             }
//           } else {
//             setCommonAlert({ open: true, msg: language.place_to_coords_error })
//           }
//         }
//       )
//     } else {
//       setCommonAlert({ open: true, msg: language.select_proper })
//     }    
//   }

//   const handleGetEstimate = (e) => {
//     e.preventDefault();
//     setOptionModalStatus(false);
//     let estimateRequest = {
//       pickup: pickupAddress,
//       drop: dropAddress,
//       carDetails: selectedCarDetails,
//       instructionData: instructionData,
//       routeDetails: tempRoute
//     };
//     dispatch(getEstimate(estimateRequest));
//   };

//   const confirmBooking = (e) => {
//     e.preventDefault();
//     if(instructionData.deliveryPerson && instructionData.deliveryPersonPhone){
//       setEstimateModalStatus(false);
//       let bookingObject = {
//         pickup: pickupAddress,
//         drop: dropAddress,
//         carDetails: selectedCarDetails,
//         userDetails: {
//           uid: userCombo.uid,
//           profile: {
//             firstName: userCombo.firstName,
//             lastName: userCombo.lastName,
//             mobile: userCombo.mobile,
//             pushToken: userCombo.pushToken
//           }
//         },
//         estimate: estimatedata.estimate,
//         instructionData: instructionData,
//         tripdate: new Date(selectedDate).toString(),
//         bookLater: bookingType === 'Book Later' ? true : false,
//         settings: settings,
//         booking_type_web: true
//       };
//       dispatch(addBooking(bookingObject));
//     }else{
//       alert(language.deliveryDetailMissing);
//     }
//   };

//   const handleOptionModalClose = (e) => {
//     e.preventDefault();
//     setOptionModalStatus(false);
//   };

//   const handleEstimateModalClose = (e) => {
//     e.preventDefault();
//     setEstimateModalStatus(false);
//     dispatch(clearEstimate());
//     setEstimateRequested(false);
//   };

//   const handleEstimateErrorClose = (e) => {
//     e.preventDefault();
//     dispatch(clearEstimate());
//     setEstimateRequested(false);
//   };

//   const handleBookingAlertClose = (e) => {
//     e.preventDefault();
//     dispatch(clearBooking());
//     dispatch(clearEstimate());
//     clearForm();
//   };

//   const clearForm = () => {
//     setUserCombo(null);
//     setPickupAddress(null);
//     setDropAddress(null);
//     setSelectedCarDetails(null);
//     setCarType(language.select_delivery);
//     setBookingType(language.book_now);
//     setEstimateRequested(false);
//   }

//   const handleBookingErrorClose = (e) => {
//     e.preventDefault();
//     dispatch(clearBooking());
//     setEstimateRequested(false);
//   };

//   const handleCommonAlertClose = (e) => {
//     e.preventDefault();
//     setCommonAlert({ open: false, msg: '' })
//   };

//   return (
//     <div className={classes.container} ref={rootRef}>
//       <Grid item xs={12} sm={12} md={8} lg={8}>
//         <Grid container spacing={2} >
//           <Grid item xs={12}>
//             <Typography component="h1" variant="h5" className={classes.title}>
//               {language.deliverybyphone}
//             </Typography>
//           </Grid>
//           <Grid item xs={12}>
//             {users ?
//               <UsersCombo
//                 className={classes.items}
//                 placeholder={language.select_user}
//                 users={users}
//                 value={userCombo}
//                 onChange={(event, newValue) => {
//                   setUserCombo(newValue);
//                 }}
//               />
//               : null}
//           </Grid>
//           <Grid item xs={12}>
//             <GoogleMapsAutoComplete
//               variant={"outlined"}
//               placeholder={language.pickup_location}
//               value={pickupAddress}
//               className={classes.items}
//               onChange={
//                 (value) => {
//                   setPickupAddress(value);
//                 }
//               }
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <GoogleMapsAutoComplete placeholder={language.drop_location}
//               variant={"outlined"}
//               value={dropAddress}
//               className={classes.items}
//               onChange={
//                 (value) => {
//                   setDropAddress(value);
//                 }
//               }
//             />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             {cartypes ?
//               <Select
//                 id="car-type-native"
//                 value={carType}
//                 onChange={handleCarSelect}
//                 variant="standard"
//                 fullWidth
//                 className={carType === language.select_delivery ? classes.inputdimmed : classes.input}
//               >
//                 <MenuItem value={language.select_delivery} key={language.select_delivery}>
//                   {language.select_delivery}
//                 </MenuItem>
//                 {
//                   cartypes.map((car) =>
//                     <MenuItem key={car.name} value={car.name}>
//                       <img src={car.image} className={classes.carphoto} alt="car types" />{car.name}
//                     </MenuItem>
//                   )
//                 }
//               </Select>
//               : null}
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <Select
//               id="booking-type-native"
//               value={bookingType}
//               onChange={handleBookTypeSelect}
//               className={classes.input}
//               variant="standard"
//               fullWidth
//               inputProps={{ 'aria-label': 'Without label' }}
//             >
//               <MenuItem key={"Book Now"} value={"Book Now"}>
//                 {language.book_now}
//               </MenuItem>
//               <MenuItem key={"Book Later"} value={"Book Later"}>
//                 {language.book_later}
//               </MenuItem>
//             </Select>
//           </Grid>
//           {bookingType === 'Book Later' ?
//             <Grid item xs={12} sm={6} >
//               <TextField
//                 id="datetime-local"
//                 label={language.booking_date_time}
//                 type="datetime-local"
//                 variant="standard"
//                 fullWidth
//                 className={classes.commonInputStyle}
//                 InputProps={{
//                   className: classes.input
//                 }}
//                 value={selectedDate}
//                 onChange={onDateChange}
//               />
//             </Grid>
//             : null}
//           <Grid item xs={12} sm={6} >
//             <Button
//               size="large"
//               onClick={handleGetOptions}
//               variant="contained"
//               //color="warning"
//               className={classes.buttonStyle}
//             >{language.phone_booking_btn}
//               </Button>
//           </Grid>
//         </Grid>
//       </Grid>
//       <Modal
//         disablePortal
//         disableEnforceFocus
//         disableAutoFocus
//         open={optionModalStatus}
//         onClose={handleOptionModalClose}
//         className={classes.modal}
//         container={() => rootRef.current}
//       >
//         <Grid container spacing={2} className={classes.paper}>
//           <Grid item xs={12} sm={12} md={12} lg={12}>
//           {selectedCarDetails && selectedCarDetails.parcelTypes?
//             <FormControl component="fieldset">
//               <FormLabel component="legend">{language.parcel_types}</FormLabel>
//               <RadioGroup name="parcelTypeIndex" value={instructionData.parcelTypeIndex} onChange={handleChange}>
//                 {selectedCarDetails.parcelTypes.map((element,index) =>
//                   <FormControlLabel key={element.description} value={index} control={<Radio />} label={ settings.symbol + ' ' +  element.amount + ' - ' +  element.description} />
//                 )}
//               </RadioGroup>
//             </FormControl>
//           :null}
//           </Grid>
//           <Grid item xs={12} sm={12} md={12} lg={12}>
//           {selectedCarDetails && selectedCarDetails.options?
//             <FormControl component="fieldset">
//               <FormLabel component="legend">{language.options}</FormLabel>
//               <RadioGroup name="optionIndex" value={instructionData.optionIndex} onChange={handleChange}>
//                 {selectedCarDetails.options.map((element,index) =>
//                   <FormControlLabel key={element.description} value={index} control={<Radio />} label={ settings.symbol + ' ' + element.amount + ' - ' + element.description} />
//                 )}
//               </RadioGroup>
//             </FormControl>
//           :null}
//           </Grid>
//           <Grid item xs={12} sm={12} md={12} lg={12}>
//           <Button onClick={handleOptionModalClose} variant="contained" color="primary">
//             {language.cancel}
//           </Button>
//           <Button onClick={handleGetEstimate} variant="contained" color="primary" style={{marginLeft:10}}>
//             {language.get_estimate}
//           </Button>
//           </Grid>
//         </Grid>
//       </Modal>
//       <Modal
//         disablePortal
//         disableEnforceFocus
//         disableAutoFocus
//         open={estimateModalStatus}
//         onClose={handleEstimateModalClose}
//         className={classes.modal}
//         container={() => rootRef.current}
//       >
//         <Grid container spacing={1} className={classes.paper}>
//             <Typography component="h3" variant="h6" color="secondary" style={{marginTop:15}}>
//                 {language.delivery_information}
//             </Typography>
//             <Grid item xs={12}>
//               <TextField
//                 color="success"
//                 variant="standard"
//                 margin="normal"
//                 fullWidth
//                 id="deliveryPerson"
//                 label={language.deliveryPerson}
//                 name="deliveryPerson"
//                 autoComplete="deliveryPerson"
//                 onChange={handleChange}
//                 value={instructionData.deliveryPerson}
//                 autoFocus
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 color="success"
//                 variant="standard"
//                 margin="normal"
//                 fullWidth
//                 id="deliveryPersonPhone"
//                 label={language.deliveryPersonPhone}
//                 name="deliveryPersonPhone"
//                 autoComplete="deliveryPersonPhone"
//                 onChange={handleChange}
//                 value={instructionData.deliveryPersonPhone}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 color="success"
//                 variant="standard"
//                 multiline rows={3}
//                 margin="normal"
//                 fullWidth
//                 id="pickUpInstructions"
//                 label={language.pickUpInstructions}
//                 name="pickUpInstructions"
//                 autoComplete="pickUpInstructions"
//                 onChange={handleChange}
//                 value={instructionData.pickUpInstructions}
//               />
//             </Grid>
            
//             {/* //To add if its type of contract to show, otherwise NOT */}

//             <Grid item xs={12}>
//               <TextField
//                 color="success"
//                 variant="standard"
//                 margin="normal"
//                 fullWidth
//                 id="deliveryInstructions"
//                 label={language.deliveryInstructions}
//                 name="deliveryInstructions"
//                 autoComplete="deliveryInstructions"
//                 onChange={handleChange}
//                 value={instructionData.deliveryInstructions}
//               />
//             </Grid>
//           <Grid item xs={12} sm={12} md={12} lg={12}>
//             <Typography color={'warning'} style={{fontSize:20}}>
//               {language.total} - {settings?settings.symbol:null} {estimatedata.estimate ? estimatedata.estimate.estimateFare : null}
//             </Typography>
//           </Grid>
//           <Grid item xs={12} sm={12} md={12} lg={12}>
//             <Button onClick={handleEstimateModalClose} variant="contained" color="primary">
//               {language.cancel}
//             </Button>
//             <Button onClick={confirmBooking} variant="contained" color="primary" style={{marginLeft:10}}>
//               {language.book_now}
//             </Button>
//           </Grid>
//         </Grid>
//       </Modal>
//       <AlertDialog open={bookingdata.booking ? true : false} onClose={handleBookingAlertClose}>{bookingdata.booking ? language.booking_success + bookingdata.booking.booking_id : null}</AlertDialog>
//       <AlertDialog open={bookingdata.error.flag} onClose={handleBookingErrorClose}>{bookingdata.error.msg}</AlertDialog>
//       <AlertDialog open={estimatedata.error.flag} onClose={handleEstimateErrorClose}>{estimatedata.error.msg}</AlertDialog>
//       <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>
//     </div>
//   );
// }