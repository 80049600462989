import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import MUIDataTable from "mui-datatables";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Chip from "@mui/material/Chip";
import WrongLocationIcon from "@mui/icons-material/WrongLocation";
import { 
  // features, dateStyle, 
  language } from "config";
import { FirebaseContext } from "common";
import AlertDialog from "../components/AlertDialog";
import ConfirmationDialogRaw from "../components/ConfirmationDialogRaw";
//import { formLabelClasses } from "@mui/material";

const DeliveryHistory = () => {
  const { api } = useContext(FirebaseContext);
  const { cancelBooking } = api;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [, setRole] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState("");
  const [data, setData] = useState([]);
  const bookinglistdata = useSelector((state) => state.bookinglistdata);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  const handleDelete = () => {
    setOpenConfirm(true);
  };

  const onConfirmClose = (value) => {
    if (value) {
      dispatch(
        cancelBooking({
          reason: value,
          booking: selectedBooking,
        })
      );
    }
    setOpenConfirm(false);
  };


  useEffect(() => {
    if (bookinglistdata.bookings) {
      setData(bookinglistdata.bookings);
    } else {
      setData([]);
    }
  }, [bookinglistdata.bookings]);

  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setRole(auth.info.profile.usertype);
    }
  }, [auth.info]);

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded",
        sort: false,
        filter: false,
      },
    },
    {
      name: "tripdate",
      label: language.booking_date,
      field: "tripdate",
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      name: "status",
      label: language.booking_status,
      field: { render: (rowData) => <span>{language[rowData.status]}</span> },
      options: {
        hint: "current delivery status",
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          if (value === "COMPLETE")
            return <Chip label="COMPLETED" color="success" size="small" />;
          else if (value === "CANCELLED")
            return <Chip label="CANCELLED" color="error" size="small" />;
          else if (value === "NEW")
            return (
              <Chip
                label="NEW"
                variant="outlined"
                color="warning"
                size="small"
              />
            );
          else if (value === "STARTED")
            return <Chip label="STARTED" color="warning" size="small" />;
          else if (value === "PENDING")
            return (
              <Chip
                label="PENDING"
                variant="outlined"
                color="warning"
                size="small"
              />
            );
          else if (value === "PAYMENT_PENDING")
            return <Chip label="PAYMENT PENDING" variant="outlined"size="small"/>;
          else if (value === "PAID")
            return <Chip label="PAID" color="success" size="small" />;
          else if (value === "REACHED")
            return <Chip label="REACHED" color="secondary" size="small" />;
          else if (value === "ARRIVED")
            return <Chip label="ARRIVED" color="secondary" size="small" />;
          else
            return (
              <Chip
                label="ACCEPTED"
                variant="outlined"
                color="secondary"
                size="small"
              />
            );
        },
      },
    },

    {
      name: "deliveryPerson",
      label: language.deliveryPerson,
      field: "deliveryPerson",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "deliveryPersonPhone",
      label: language.deliveryPersonPhone,
      field: "deliveryPersonPhone",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "dropAddress",
      label: language.drop_address,
      field: "dropAddress",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "pickup_image",
      label: language.take_pickup_image,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) =>
          !!value ? (
            <img alt="Pick Up" src={value} style={{ width: 150 }} />
          ) : null,
      },
    },
    {
      name: "deliver_image",
      label: language.take_deliver_image,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) =>
          !!value ? (
            <img alt="Deliver" src={value} style={{ width: 150 }} />
          ) : null,
      },
    },
    {
      name: "customer_paid",
      label: language.Customer_paid,
      field: "customer_paid",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "otp",
      label: "Proof PIN",
      field: "customer_paid",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "status",
      label: language.cancel_booking,
      // field: { render: (rowData) => <span>{language[rowData.status]}</span> },
      options: {
        hint: "You can cancel a delivery ONLY while NEW",
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          if (value === "NEW") {
            const handleClick = () => {
              const bookingId = tableMeta.rowData?.[0];
              const booking = data.find((b) => b.id === bookingId);
              if (!booking) {
                setCommonAlert({
                  open: false,
                  msg: "Semething went wrong, please retry",
                });
              } else {
                setSelectedBooking(booking);
                handleDelete();
              }
            };
            return (
              <Chip
                size="small"
                color="error"
                label="Cancel"
                onClick={handleClick}
                onDelete={handleClick}
                deleteIcon={<WrongLocationIcon />}
              />
            );
          } else {
            return <Chip label="ONGOING" size="small" />;
          }
        },
      },
    },
  ];

  const options = {
    filter: true,
    onFilterChange: (changedColumn, filterList) => {},
    selectableRows: false,
    filterType: "dropdown",
    rowsPerPage: 50,
    rowsPerPageOptions: [50, 100, 200],
    expandableRows: true,
    expandableRowsOnClick: true,
    searchPlaceholder: "Search deliveries",
    renderExpandableRow: (rowData) => {
      return (
        <>
          <tr>
            <td colSpan={12}>
              <TableContainer component={Paper}>
                <Table style={{ minWidth: "300" }} aria-label="simple table">
                  <TableHead></TableHead>
                  <TableBody>
                    {columns.map((col, index) => {
                      return (
                        <TableRow>
                          <TableCell>
                            {col.label}: {rowData[index]}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </td>
          </tr>
        </>
      );
    },
  };

  return (
    <>
      <MUIDataTable
        title={language.booking_title}
        data={data}
        columns={columns}
        options={options}
      />
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
      <ConfirmationDialogRaw
        open={openConfirm}
        onClose={onConfirmClose}
        value={""}
      />
    </>
  );
};

export default DeliveryHistory;


// import React,{ useState, useEffect, useContext } from 'react';
// import MaterialTable from 'material-table';
// import CircularLoading from "../components/CircularLoading";
// import { useSelector, useDispatch } from "react-redux";
// import ConfirmationDialogRaw from '../components/ConfirmationDialogRaw';
// import { 
//   features,
//   dateStyle,
//   language
// } from 'config';
// import { FirebaseContext } from 'common';

// const DeliveryHistory = () => {
//   const { api } = useContext(FirebaseContext);
//   const {
//     cancelBooking
//   } = api;
//   const dispatch = useDispatch();
//   const auth = useSelector(state => state.auth);
//   const [role, setRole] = useState(null);

//   const columns =  [
//       { title: language.deliveryPerson, field: 'deliveryPerson' },
//       { title: language.deliveryPersonPhone, field: 'deliveryPersonPhone' },
//       { title: language.booking_date, field: 'tripdate', render: rowData => rowData.tripdate?new Date(rowData.tripdate).toLocaleString(dateStyle):null},
//       { title: language.booking_status, field: 'status', render: rowData => <span>{language[rowData.status]}</span> },
//       { title: language.drop_address, field: 'dropAddress' },
//       { title: language.Customer_paid, field: 'customer_paid'},
//       { title: language.parcel_type, render: rowData => <span>{rowData.parcelTypeSelected?rowData.parcelTypeSelected.description + " (" + rowData.parcelTypeSelected.amount + ")":""}</span> },
//       { title: language.parcel_option, render: rowData => <span>{rowData.optionSelected?rowData.optionSelected.description + " (" + rowData.optionSelected.amount + ")":""}</span> },
//       { title: language.trip_start_time, field: 'trip_start_time' },
//       { title: language.trip_end_time, field: 'trip_end_time' },
//       { title: language.pickUpInstructions, field: 'pickUpInstructions' },
//       { title: language.assign_driver, field: 'driver_name' },  
//       //{ title: language.discount_ammount, field: 'discount'},
//       { title: language.booking_id, field: 'id' }
//   ];
//   const [data, setData] = useState([]);
//   const [openConfirm, setOpenConfirm] = useState(false);
//   const [selectedBooking, setSelectedBooking] = useState('');
//   const bookinglistdata = useSelector(state => state.bookinglistdata);

//   useEffect(()=>{
//         if(bookinglistdata.bookings){
//             setData(bookinglistdata.bookings);
//         }else{
//           setData([]);
//         }
//   },[bookinglistdata.bookings]);

//   useEffect(() => {
//     if(auth.info && auth.info.profile){
//       setRole(auth.info.profile.usertype);
//     }
//   }, [auth.info]);

//   const onConfirmClose=(value)=>{
//     if(value){
//       dispatch(cancelBooking({
//         reason:value,
//         booking:selectedBooking
//       }));
//     }
//     setOpenConfirm(false);
//   }
  
//   return (
//     bookinglistdata.loading? <CircularLoading/>:
//     <div>
//     <MaterialTable
//       title={language.booking_title}
//       columns={columns}
//       data={data}
//       options={{
//         actionsColumnIndex: -1,
//         exportButton: features.AllowCriticalEditsAdmin,
//         sorting: true,
//         searchFieldAlignment:'left',
//         headerStyle: {
//           backgroundColor: '#D3D3D3',
//           color: '#494A6B',
//           fontWeight:"bold"
//         },
//       }}
//       actions={[
//         rowData => ({
//           icon: 'cancel',
//           tooltip: language.cancel_booking,
//           disabled: rowData.status==='NEW' || rowData.status==='ACCEPTED' || rowData.status==='PAYMENT_PENDING'? false:true,
//           onClick: (event, rowData) => {
//             if(features.AllowCriticalEditsAdmin && (role==='dispatcher' || role ==='admin')){
//               if(rowData.status==='NEW' || rowData.status==='ACCEPTED'){
//                 setSelectedBooking(rowData);
//                 setOpenConfirm(true);
//               }else{
//                 setTimeout(()=>{
//                   dispatch(cancelBooking({
//                     reason: language.cancelled_incomplete_booking,
//                     booking:rowData
//                   }));
//                 },1500);
//               }
//             }else{
//               alert(language.demo_mode);
//             }
//           }         
//         }),
//       ]}
//     />
//     <ConfirmationDialogRaw
//       open={openConfirm}
//       onClose={onConfirmClose}
//       value={''}
//     />
//     </div>

//   );
// }

// export default DeliveryHistory;
