import { container, title } from "assets/jss/material-kit-react.js";

const landingPageStyle = {
  container: {
    zIndex: "12",
    color: "#000000",
    backgroundColor:"transparent",
    ...container,
    alignContent:'center'
    
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "0px",
    minHeight: "0px",
    color: "#000000",
    textDecoration: "none"
  },
  gridcontainer:{
    alignContent:'center'
  },
  pageHeader: {
    minHeight: "120vh",
    height: "auto",
    display: "inherit",
    position: "relative",
    margin: "0",
    padding: "0",
    border: "0",
    alignItems: "center",
    // "&:before": {
    //   background: "rgba(0, 0, 0, 0.5)"
    // },
    // "&:before,&:after": {
    //   position: "absolute",
    //   zIndex: "1",
    //   width: "100%",
    //   height: "100%",
    //   display: "block",
    //   left: "0",
    //   top: "0",
    //   content: '""'
    // },
    "& footer li a,& footer li a:hover,& footer li a:active": {
      color: "#000000"
    },
    "& footer": {
      position: "absolute",
      bottom: "30",
      width: "100%"
    }
  },
  // subtitle: {
  //   fontSize: "1.313rem",
  //   maxWidth: "500px",
  //   margin: "10px auto 0"
  // },
  // main: {
  //   background: "#000000",
  //   position: "relative",
  //   zIndex: "3"
  // },
  // mainRaised: {
  //   margin: "-30px 30px 0px",
  //   borderRadius: "6px",
  //   boxShadow:
  //     "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  // },
  // main2: {
  //   background: "#e8e8e8",
  //   position: "relative",
  //   zIndex: "3"
  // },
  // mainRaised2: {
  //   margin: "-60px 30px 0px",
  //   borderRadius: "6px",
  //   boxShadow:
  //     "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  // },
  items:{
    margin:0,
    width:'100%'
  },
  menuItems:{
    width:'100%',
  },
  input: {
    //borderRadius: 6,
    position: 'relative',
    backgroundColor:  'transparent',
    fontSize: 16,
    padding: '5px 26px 10px 0px',
    color:"#000000"
  },
  inputdimmed: {
    //borderRadius: 6,
    position: 'relative',
    backgroundColor:  'transparent',
    fontSize: 16,
    padding: '5px 26px 10px 0px',
    color:"#000000"
  },
  commonInputStyle:{
    //borderRadius: 6,
    backgroundColor: 'transparent',
  },
  carphoto:{
    height: '22px',
    marginRight:'10px'
  }

};

export default landingPageStyle;
