import React, { useContext } from 'react';
import {
  Typography,
  ListItemIcon,
  Divider,
  MenuList,
  MenuItem,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";
import RoomIcon from '@material-ui/icons/Room';
import ListIcon from '@material-ui/icons/ListAlt';
import HelpIcon from '@material-ui/icons/Help';
import ExitIcon from '@material-ui/icons/ExitToApp';
import OfferIcon from '@material-ui/icons/LocalOffer';
import PeopleIcon from '@material-ui/icons/People';
import SportsMotorsportsIcon from '@material-ui/icons/SportsMotorsports';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import InboxIcon from '@material-ui/icons/Inbox';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CancelIcon from '@material-ui/icons/Cancel';
import NotifyIcon from '@material-ui/icons/NotificationsActive';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SettingsRemoteIcon from '@material-ui/icons/SettingsRemote';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import { language } from 'config';
import assets from 'assets';
import { FirebaseContext } from 'common';

function AppMenu() {
  const { api } = useContext(FirebaseContext);
  const {
    signOut
  } = api;
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const LogOut = () => {
    dispatch(signOut());
  };

  let isAdmin = auth.info && auth.info.profile && auth.info.profile.usertype === 'admin';
  let isFleetAdmin = auth.info && auth.info.profile && auth.info.profile.usertype === 'fleetadmin';
  let isDispatcher = auth.info && auth.info.profile && auth.info.profile.usertype === 'dispatcher';
  let isDriver = auth.info && auth.info.profile && auth.info.profile.usertype === 'driver';
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#fff' }}>
      <a href="/">
      <img style={{ marginTop: '10px', marginBottom: '5px', width: '75px', height: '40px' }} src={assets.logo138x75white} alt="Logo" /></a>
    
      </div>
      <MenuList autoFocusItem="true" >
      {/* {isAdmin?
      <MenuItem component={Link} to="/">
          <Typography variant="inherit">{language.customers_delivery}</Typography>
        </MenuItem>
        :null} */}
      {isAdmin || isFleetAdmin?
      <MenuItem>
          <Typography variant="inherit">TRACK DRIVERS-ORDERS</Typography>
          </MenuItem>
          :null}
          {isAdmin || isFleetAdmin?
          <MenuItem component={Link} to="/dashboard">
          <ListItemIcon>
            <RoomIcon color="error" />
          </ListItemIcon>
          <Typography variant="inherit">{language.dashboard_text}</Typography>
        </MenuItem>
        :null}
        {isAdmin || isFleetAdmin?
        <MenuItem component={Link} to="/bookings">
          <ListItemIcon>
            <DriveEtaIcon color="success" />
          </ListItemIcon>
          <Typography variant="inherit">{language.booking_history}</Typography>
        </MenuItem>
        :null}
        {isAdmin || isFleetAdmin?
        <Divider />
        :null}
        {isDispatcher ?
        <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#f0f0f0' }}>
        <a target="_blank" rel="noreferrer" href="https://www.drop4u.net/contact">
        <Typography color="primary" align="center" variant="subtitle1">{language.support_guide}  </Typography></a>
        <ListItemIcon align="right">
            <HelpIcon color="error" />
          </ListItemIcon>
        </div>
    :null}
    {isAdmin || isDispatcher || isDriver?
        <MenuItem>
        <Typography variant="inherit">DELIVERIES</Typography>
    </MenuItem>
    :null}
    {isAdmin?
        <MenuItem component={Link} to="/phonedelivery">
          <ListItemIcon>
            <AddCircleIcon color="warning" />
          </ListItemIcon>
          <Typography variant="inherit">{language.newdelivery}</Typography>
        </MenuItem>
      :null}
      {isDispatcher ?
        <MenuItem component={Link} to="/newdelivery">
          <ListItemIcon>
          <AddCircleIcon color="warning" />
          </ListItemIcon>
          <Typography variant="inherit">{language.newdelivery}</Typography>
        </MenuItem>
      : null}
      {isDispatcher || isDriver?
      <Divider />
      :null}
      {isDispatcher?
      <MenuItem>
          <Typography variant="inherit">MY ORDERS</Typography>
          </MenuItem>
          :null}
        {isDispatcher || isDriver?
           <MenuItem component={Link} to="/userbookings">
           <ListItemIcon>
             <DriveEtaIcon color="success"/>
           </ListItemIcon>
           <Typography variant="inherit">{language.your_deliveries}</Typography>
         </MenuItem>
        :null}
         {/* {isDispatcher ?
           <MenuItem component={Link} to="/qrparcels">
           <ListItemIcon>
             <QrCode2Icon color="secondary"/>
           </ListItemIcon>
           <Typography variant="inherit">{language.your_qrparcels}</Typography>
         </MenuItem>
        :null} */}
        {isAdmin?
        <MenuItem component={Link} to="/cartypes">
          <ListItemIcon>
            <InboxIcon color="warning" />
          </ListItemIcon>
          <Typography variant="inherit">{language.delivery_type}</Typography>
        </MenuItem>
        :null}
        {isAdmin?
           <MenuItem component={Link} to="/admininvoices">
           <ListItemIcon>
             <ListIcon />
           </ListItemIcon>
           <Typography variant="inherit">{language.admin_invoices}</Typography>
         </MenuItem>
        :null}
        {isDispatcher?
           <MenuItem component={Link} to="/userinvoices">
           <ListItemIcon>
             <ListIcon />
           </ListItemIcon>
           <Typography variant="inherit">{language.your_invoices}</Typography>
         </MenuItem>
        :null}
        {isAdmin?
        <MenuItem component={Link} to="/cancelreasons">
          <ListItemIcon>
            <CancelIcon color="error" />
          </ListItemIcon>
          <Typography variant="inherit">{language.cancellation_reasons}</Typography>
        </MenuItem>
        :null}
        <Divider />
        {isAdmin || isFleetAdmin?
        <MenuItem>
        <Typography variant="inherit">PEOPLE</Typography>
    </MenuItem>
    :null}
        {isAdmin?
        <MenuItem component={Link} to="/dispatchers">
          <ListItemIcon>
            <PeopleIcon color="primary" />
          </ListItemIcon>
          <Typography variant="inherit">{language.dispatchers}</Typography>
        </MenuItem>
        :null}
        {isAdmin || isFleetAdmin?
        <MenuItem component={Link} to="/drivers">
          <ListItemIcon>
            <SportsMotorsportsIcon color="primary" />
          </ListItemIcon>
          <Typography variant="inherit">{language.drivers}</Typography>
        </MenuItem>
        :null}
        {isAdmin?
        <MenuItem component={Link} to="/fleetadmins">
          <ListItemIcon>
            <EmojiPeopleIcon color="primary" />
          </ListItemIcon>
          <Typography variant="inherit">{language.fleetadmins}</Typography>
        </MenuItem>
        :null}
        <Divider />
        {isAdmin || isFleetAdmin?
        <MenuItem>
        <Typography variant="inherit">FINANCE</Typography>
    </MenuItem>
    :null}
     {isAdmin?
        <MenuItem component={Link} to="/addtowallet">
          <ListItemIcon>
            <AccountBalanceWalletIcon color="success" />
          </ListItemIcon>
          <Typography variant="inherit">{language.add_to_wallet}</Typography>
        </MenuItem>
        :null}
        {isDispatcher?
        <MenuItem component={Link} to="/profilewallet">
          <ListItemIcon>
            <AccountBalanceWalletIcon color="primary"/>
          </ListItemIcon>
          <Typography variant="inherit">{language.profile_wallet}</Typography>
        </MenuItem>
        :null}
        {isAdmin?
        <MenuItem component={Link} to="/earningreports">
          <ListItemIcon>
            <PriceCheckIcon color="success"/>
          </ListItemIcon>
          <Typography variant="inherit">{language.earning_reports}</Typography>
        </MenuItem>
        :null}
        {isAdmin || isFleetAdmin?
        <MenuItem component={Link} to="/driverearning">
          <ListItemIcon>
            <MonetizationOnIcon color="success" />
          </ListItemIcon>
          <Typography variant="inherit">{language.driver_earning}</Typography>
        </MenuItem>
        :null}
        {isAdmin?
        <MenuItem component={Link} to="/withdraws">
          <ListItemIcon>
          <CurrencyExchangeIcon color="success" />
          </ListItemIcon>
          <Typography variant="inherit">{language.withdraws}</Typography>
        </MenuItem>
        :null}
        <Divider />
        {isAdmin?
        <MenuItem>
        <Typography variant="inherit">ADVERTISING</Typography>
    </MenuItem>
    :null}
        {isAdmin?
        <MenuItem component={Link} to="/promos">
          <ListItemIcon>
            <OfferIcon color="secondary" />
          </ListItemIcon>
          <Typography variant="inherit">{language.promo}</Typography>
        </MenuItem>
        :null}
        {isAdmin?
        <MenuItem component={Link} to="/notifications">
          <ListItemIcon>
            <NotifyIcon color="secondary" />
          </ListItemIcon>
          <Typography variant="inherit">{language.push_notification_title}</Typography>
        </MenuItem>
        :null}
        <Divider />
        <MenuItem>
        <Typography variant="inherit">OPTIONS</Typography>
    </MenuItem>
    <MenuItem component={Link} to="/profile">
          <ListItemIcon>
            <PersonOutlineIcon />
          </ListItemIcon>
          <Typography variant="inherit">{language.profile}</Typography>
        </MenuItem>
        {isAdmin?
        <MenuItem component={Link} to="/settings">
          <ListItemIcon>
            <SettingsRemoteIcon />
          </ListItemIcon>
          <Typography variant="inherit">{language.settings_title}</Typography>
        </MenuItem>
        :null}
        <MenuItem onClick={LogOut}>
          <ListItemIcon>
            <ExitIcon />
          </ListItemIcon>
          <Typography variant="inherit">{language.logout}</Typography>
        </MenuItem>
      </MenuList>
    </div>
  );
}

export default AppMenu;